<template>
  <div style="background-color: #f6f6f6">
    <van-nav-bar
      title="店铺详情"
      left-arrow
      @click-left="$router.back(-1)"
      fixed
      placeholder
    />

    <div style="position: fixed; top: 46px; left: 0; z-index: 1">
      <top-small
        v-if="detail.storeType === '1'"
        :value="detail"
        @saveCollect="saveCollect"
        @cancelCollect="cancelCollect"
      ></top-small>
      <top-big
        v-else-if="detail.storeType === '2'"
        :value="detail"
        @saveCollect="saveCollect"
        @cancelCollect="cancelCollect"
      ></top-big>

      <div class="search-bar">
        <div class="input-box">
          <van-icon name="search" />
          <van-field
            placeholder="搜索"
            disabled
            @click="
              $router.push({ path: 'makerSearch', query: { id: shopId } })
            "
          />
        </div>
        <div style="width: 40%; padding-left: 10px">
          <van-tabs
            v-model="active"
            color="#FF5200"
            line-width="10"
            title-active-color="#FF5200"
            @click="tabClick"
          >
            <van-tab title="首页"></van-tab>
            <van-tab title="全部"></van-tab>
            <van-tab title="新品"></van-tab>
          </van-tabs>
        </div>
      </div>
    </div>

    <div
      :style="
        detail.storeType === '1'
          ? 'height: calc(21vw + 42px)'
          : 'height: calc(41.5vw + 42px)'
      "
    ></div>

    <div v-if="active === 0">
      <div v-for="(item, index) in fitmentList" :key="index">
        <notice-bar
          v-if="item.modulesType == 4 && item.styleType == 1"
          :value="item.shopWordsInfos"
        ></notice-bar>
        <navigate-bar
          v-if="item.modulesType == 2"
          :value="item.shopNavigationInfos"
          :shopId="shopId"
        ></navigate-bar>
        <goods-big
          v-if="item.modulesType == 1 && item.styleType == 3"
          :value="item.shopProductInfos"
        ></goods-big>
        <goods-list
          v-if="item.modulesType == 1 && item.styleType == 2"
          :value="item.shopProductInfos"
        ></goods-list>
        <goods-two
          v-if="item.modulesType == 1 && item.styleType == 1"
          :value="item.shopProductInfos"
        ></goods-two>
        <advertise-swipe
          v-if="item.modulesType == 3 && item.styleType == 3"
          :value="item.shopAdvertInfos"
          :shopId="shopId"
        ></advertise-swipe>
        <advertise-big
          v-if="item.modulesType == 3 && item.styleType == 1"
          :value="item.shopAdvertInfos"
          :shopId="shopId"
        ></advertise-big>
        <advertise-two
          v-if="item.modulesType == 3 && item.styleType == 2"
          :value="item.shopAdvertInfos"
          :shopId="shopId"
        ></advertise-two>
        <advertise-slide-big
          v-if="item.modulesType == 3 && item.styleType == 5"
          :value="item.shopAdvertInfos"
          :shopId="shopId"
        ></advertise-slide-big>
        <advertise-slide-little
          v-if="item.modulesType == 3 && item.styleType == 4"
          :value="item.shopAdvertInfos"
          :shopId="shopId"
        ></advertise-slide-little>
      </div>
    </div>

    <van-list
      v-else
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
      :immediate-check="false"
      :style="
        detail.storeType === '1' ? '' : 'height: calc(100vh - 41.5vw - 88px)'
      "
    >
      <goods-two :value="goods"></goods-two>
    </van-list>
  </div>
</template>

<script>
import topSmall from "@/components/creator-box/top-small";
import topBig from "@/components/creator-box/top-big";
import goodsTwo from "@/components/creator-box/goods-two";
import noticeBar from "@/components/creator-box/notice-bar";
import navigateBar from "@/components/creator-box/navigate-bar";
import goodsBig from "@/components/creator-box/goods-big";
import goodsList from "@/components/creator-box/goods-list";
import advertiseSwipe from "@/components/creator-box/advertise-swipe";
import advertiseBig from "@/components/creator-box/advertise-big";
import advertiseTwo from "@/components/creator-box/advertise-two";
import advertiseSlideBig from "@/components/creator-box/advertise-slide-big.vue";
import advertiseSlideLittle from "@/components/creator-box/advertise-slide-little.vue";

import {
  getShelfGoods,
  getModules,
  getBasic,
  saveCollect,
  cancelCollect,
} from "@/api/makerStore";
export default {
  name: "makerStoreDetail",
  components: {
    topSmall,
    topBig,
    noticeBar,
    navigateBar,
    goodsBig,
    goodsList,
    goodsTwo,
    advertiseSwipe,
    advertiseBig,
    advertiseTwo,
    advertiseSlideBig,
    advertiseSlideLittle,
  },
  data() {
    return {
      active: 0,

      detail: {
        storeType: "1",
      },
      fitmentList: [],

      shopId: null,
      isNew: null,
      current: 0,

      total: 0,
      goods: [],
      loading: false,
      finished: false,
    };
  },
  created() {
    this.shopId = this.$route.query.id;

    this.getModules();
    this.getBasic();
  },
  computed: {},
  methods: {
    // 获取商品
    getShelfGoods() {
      const obj = {
        shopId: this.shopId,
        isNew: this.isNew,
        current: this.current,
      };

      getShelfGoods(obj).then((res) => {
        console.log(res);
        const result = res.data.data;
        // result.records.map((x) => {
        //   x.show = true;
        // });
        this.goods.push(...result.records);

        this.total = result.total;
        this.loading = false;

        if (this.goods.length >= this.total) {
          this.finished = true;
        }
      });
    },
    // 获取装修模块
    getModules() {
      getModules({ shopId: this.shopId }).then((res) => {
        console.log(res);
        this.fitmentList = res.data.data;
        this.fitmentList.map((x) => {
          if (x.modulesType === "1") {
            x.shopProductInfos.map((i) => {
              if (i.storeProductInfoDTO) {
                i.salePrice = i.storeProductInfoDTO.salePrice;
                i.saleNum = i.storeProductInfoDTO.saleNum;
                i.subject = i.storeProductInfoDTO.subject;
                i.images = i.storeProductInfoDTO.images;
                i.id = i.storeProductInfoDTO.id;
                // i.show = true;
              }
            });
          }
        });
      });
    },
    // 获取店铺基本信息
    getBasic() {
      getBasic({ shopId: this.shopId }).then((res) => {
        console.log(res);
        this.detail = res.data.data;
      });
    },
    // tab栏切换
    tabClick(name, title) {
      if (title !== "首页") {
        this.isNew = title === "全部" ? null : "1";
        this.goods = [];
        this.current = 0;
        this.finished = false;
        this.onLoad();
      }
    },
    // 获取商品数据
    onLoad() {
      this.current++;
      this.getShelfGoods();
    },
    saveCollect() {
      let obj = {
        type: "7",
        relationId: this.shopId,
      };
      saveCollect(obj).then((res) => {
        console.log(res);
        this.$toast("收藏成功");
        this.getBasic();
      });
    },
    cancelCollect() {
      cancelCollect(this.detail.collectId).then((res) => {
        console.log(res);
        this.$toast("取消收藏成功");
        this.getBasic();
      });
    },
  },
};
</script>

<style lang="less" scoped>
.search-bar {
  height: 32px;
  padding: 5px 3vw;
  background-color: #fff;
  display: flex;
  .input-box {
    width: 40%;
    position: relative;
    .van-icon {
      position: absolute;
      top: 50%;
      left: 14px;
      transform: translateY(-50%);
      z-index: 1;
      font-size: 16px;
    }
    .van-field {
      height: 32px;
      border-radius: 16px;
      background-color: #f6f6f6;
      padding-left: 32px;
      /deep/ .van-field__body {
        height: 12px;
      }
    }
  }
  /deep/ .van-tabs__wrap {
    height: 32px;
  }
}
.van-list {
  height: calc(100vh - 21vw - 88px);
  overflow: auto;
}
</style>